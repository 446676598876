import React, { PropsWithChildren, useEffect, useState } from "react";

import { useLocalStorage } from "CoreHooks";
import { LOCAL_STORAGE_CONSTANTS } from "Constants";
import { languageOptions, dictionaryList } from "../../languages";
import { useQoplaStore } from "Stores/qoplaStore";
import { CompanyLocale, CountryLocaleId, ILanguages, Languages } from "Types";

// Temporary: Re-Exporting to support importing these types from "Providers" until completely migrated to "Types"
export { Languages };
export type { ILanguages };

// Re-exporting these as a permanent part of LanguageProvider, so other files don't need to import directly from `languages.ts`
export { dictionaryList, languageOptions };

export type Languagekey = keyof (typeof dictionaryList)[Languages.SV];
export interface TranslateFunc {
    (tid: Languagekey, overriddenLanguage?: string): string;
}
export interface TranslateWithArgumentFunc {
    (originalStringName: Languagekey, argument: number | string): string;
}

export interface ILanguageContext {
    userLanguage: ILanguages;
    //@ts-ignore
    userLocale: Intl.Locale;
    dictionary: any;
    availableLanguages: string[];
    isMissingTranslationKey: (key: string) => boolean;
    onUserLanguageChange: (lang: ILanguages) => void;
    translate: TranslateFunc;
    translateWithArgument: TranslateWithArgumentFunc;
    translateWithMultipleArguments: (translationKey: Languagekey, replacedWith: (string | number)[]) => string;
}

export const LanguageContext = React.createContext<ILanguageContext | undefined>(undefined as any);

/**
 * @description Added for the class components that we currently have as you can't use hooks
 * @example <LanguageConsumer>{(props) => return (<></>)}</LanguageConsumer>
 */
export const LanguageConsumer = LanguageContext.Consumer;

/** Note: This is in the test suite */
export const LanguageProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [defaultLocale, setDefaultLocale] = useLocalStorage(LOCAL_STORAGE_CONSTANTS.USER_LOCALE);

    const { companyLocale } = useQoplaStore();

    const getUsersBrowserLanguage = () => {
        const userLocale =
            navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
        if (userLocale) {
            //@ts-ignore
            const locale = new Intl.Locale(userLocale);
            if (locale) {
                const language = locale.language || Languages.SV;
                const languagesAvailable = Object.values(Languages) as string[];
                //@ts-ignore
                if (languagesAvailable.includes(language)) {
                    return language;
                }
            }
        }
        return Languages.SV;
    };

    //@ts-ignore
    const getUserLocale = (userLanguage: ILanguages, companyLocale?: CompanyLocale) => {
        if (!companyLocale) {
            //@ts-ignore
            const _userLocale = new Intl.Locale(CountryLocaleId.sv_SE, { language: userLanguage });
            return _userLocale;
        }
        //@ts-ignore
        return new Intl.Locale(companyLocale.baseName, { language: userLanguage });
    };

    //@ts-ignore
    const [userLocale, setUserLocale] = useState<Intl.Locale>(() => {
        if (defaultLocale) {
            //@ts-ignore
            return new Intl.Locale(defaultLocale);
        }
        /* const userLanguage = getUsersBrowserLanguage();
        //TODO: add back in when translation is completed
        //@ts-ignore
        return new Intl.Locale(CountryLocaleId.sv_SE, { language: userLanguage }); */

        //@ts-ignore
        return new Intl.Locale(CountryLocaleId.sv_SE, { language: Languages.SV });
    });

    const onSetUserLocale = (lang: ILanguages, companyLocale?: CompanyLocale) => {
        const userCompanyLocale = getUserLocale(lang, companyLocale);
        setUserLocale(userCompanyLocale);
        //@ts-ignore
        setDefaultLocale(userCompanyLocale.baseName);
    };

    const onUserLanguageChange = (lang: ILanguages) => {
        const newLanguage = languageOptions[lang] ? lang : Languages.SV;
        onSetUserLocale(newLanguage, companyLocale);
    };

    const isMissingTranslationKey = (key: string) => {
        //@ts-ignore
        const checkTranslation = dictionaryList[userLocale.language][key];
        //@ts-ignore
        return typeof checkTranslation === "undefined";
    };

    /**
     *
     * @param {string} tid string value key
     * @param {string} overriddenLanguage language
     * @returns {string} translation
     */
    const translate = (tid: Languagekey, overriddenLanguage?: string): string => {
        //@ts-ignore
        const languageToUse = overriddenLanguage || userLocale.language;
        //@ts-ignore
        const translatedText = dictionaryList[languageToUse][tid];
        //@ts-ignore
        if (typeof translatedText !== "undefined") {
            return translatedText;
        }
        //@ts-ignore
        return dictionaryList[languageToUse]["translationMissing"];
    };

    const translateWithArgument = (originalStringName: Languagekey, argument: number | string) => {
        return translate(originalStringName).replace("[arg]", argument as string);
    };

    /**
     * [Function] Enter multiple args into the translation file to replace
     * @description SEE example for translation string
     * @param {Languagekey} translationKey key in file
     * @param {(string | number)[]} replacedWith string array
     * @returns {string} translated string as per user locale language
     * @example "test [arg][0] this is a [arg][1]"
     */
    const translateWithMultipleArguments = (translationKey: Languagekey, replacedWith: (string | number)[]) => {
        const translation = translate(translationKey);
        return (
            replacedWith.reduce((translatedString: string, replace: string | number, index: number) => {
                return translatedString.replace("[arg][" + index + "]", replace as string);
            }, translation) ?? ""
        );
    };

    useEffect(() => {
        if (companyLocale) {
            onSetUserLocale(userLocale.language as ILanguages, companyLocale);
        }
    }, [companyLocale]);

    const value = {
        //@ts-ignore
        userLanguage: userLocale.language as ILanguages,
        userLocale,
        //@ts-ignore
        dictionary: dictionaryList[userLocale.language as ILanguages] ?? dictionaryList["sv"],
        availableLanguages: Object.keys(Languages),
        isMissingTranslationKey,
        onUserLanguageChange,
        translate,
        translateWithArgument,
        translateWithMultipleArguments
    };

    return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};
/** Note: This is in the test suite */
export const useLanguage = () => {
    const ctx = React.useContext(LanguageContext);
    if (!ctx) {
        throw new Error("useLanguage must be within LanguageProvider");
    }
    return ctx;
};

export const withLanguage =
    <P extends object>(Component: React.ComponentType<P & { languageState: ILanguageContext }>): React.FC<P> =>
        (props: P) =>
        (
            <LanguageContext.Consumer>
                {state => {
                    if (!state) {
                        throw new Error("withLanguage must be used within a LanguageProvider");
                    }

                    return <Component {...props} languageState={state} />;
                }}
            </LanguageContext.Consumer>
        );
