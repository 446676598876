import React, { createContext, PropsWithChildren, useMemo } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { modals, ModalDefinition } from "./modals";
import { useModalStore } from "Stores";

interface IActiveModal {
    name: string;
    // The input passed into the modal when calling openModal
    //  Can be anything the modal defines
    modalProps: any;
}

const ModalContext = createContext<IModalContext<any>>(null as any);
interface IModalContext<T extends { [prop: string]: any }> {
    activeModals: IActiveModal[];
    openModal: (modalName: string | ModalDefinition, modalProps?: T) => void;
    closeModal: (modalName: string | ModalDefinition) => void;
    closeAllModals: () => void;
    isModalOpen: (modal: ModalDefinition) => boolean;
    modalContent: T;
}

const ModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const modalStore = useModalStore();

    const value = useMemo(
        () => ({
            activeModals: modalStore.activeModals,
            openModal: modalStore.openModal,
            closeModal: modalStore.closeModal,
            closeAllModals: modalStore.closeAllModals,
            isModalOpen: modalStore.isModalOpen,
            modalContent: {}
        }),
        [modalStore.activeModals]
    );

    return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

const ModalRenderer = (props: any) => (
    <ModalContext.Consumer>
        {state => {
            return (
                <AnimatePresence>
                    {state.activeModals.map(({ name: modalName, modalProps }: IActiveModal) => {
                        // TODO: Change (modals as any) and set strict type in modals.ts
                        const modal = (modals as any)[modalName];
                        const { component: ModalComponent } = modal;

                        return (
                            <motion.div key={modalName}>
                                <ModalComponent key={modalName} {...state} {...props} modalContent={modalProps} />
                            </motion.div>
                        );
                    })}
                </AnimatePresence>
            );
        }}
    </ModalContext.Consumer>
);

const withModal = (Component: any) => (props: any) =>
    <ModalContext.Consumer>{state => <Component {...props} modalState={state} />}</ModalContext.Consumer>;

const withModals =
    (...whichModals: any[]) =>
    (Component: any) =>
    (props: any) =>
        (
            <ModalContext.Consumer>
                {state => {
                    if (state.activeModals.length > 0) {
                        const modals = whichModals.map(modal => {
                            return state.activeModals.find(({ name }) => {
                                return name === modal;
                            });
                        });

                        const mergedProps = modals.reduce((allProps, modal) => {
                            const modalProps = modal ? modal.modalProps : {};
                            allProps = { ...allProps, ...modalProps };
                            return allProps;
                        }, {});

                        return <Component {...props} modalState={state} modalProps={mergedProps} />;
                    } else {
                        return <Component {...props} modalState={state} />;
                    }
                }}
            </ModalContext.Consumer>
        );

const useModal = () => {
    const ctx = React.useContext(ModalContext);
    if (!ctx) {
        throw new Error("useModal must be within a ModalProvider");
    }
    return ctx;
};

const ModalConsumer = ModalContext.Consumer;

export { ModalProvider, ModalConsumer, useModal, withModal, withModals, ModalRenderer, ModalContext };
export type { IModalContext };
