import React from "react";

import { Button } from "Atoms";
import { Modal, ModalActions, ModalBody, ModalCloseBtn, ModalHeader } from "Molecules";
import { IModalContext, useModal } from "./ModalProvider";
import { modals } from "./modals";

export const TestModalOne: React.FC<IModalContext<{}>> = props => {
    const { openModal } = useModal();

    const onCloseModal = () => props.closeModal(modals.testModalOne);

    return (
        <Modal open size="xl" onClose={onCloseModal}>
            <ModalHeader>
                Modal 1
                <ModalCloseBtn />
            </ModalHeader>
            <ModalBody>Modal 1</ModalBody>
            <ModalActions>
                <Button onClick={() => openModal(modals.testModalTwo)}>Open modal 2</Button>
            </ModalActions>
        </Modal>
    );
};

export const TestModalTwo: React.FC<IModalContext<{}>> = props => {
    const { openModal } = useModal();

    const onCloseModal = () => props.closeModal(modals.testModalTwo);

    return (
        <Modal open size="lg" onClose={onCloseModal}>
            <ModalHeader>Modal 2</ModalHeader>
            <ModalBody>Modal 2</ModalBody>
            <ModalActions>
                <Button onClick={() => openModal(modals.testModalThree)}>Open modal 3</Button>
            </ModalActions>
        </Modal>
    );
};

export const TestModalThree: React.FC<IModalContext<{}>> = props => {
    const onCloseModal = () => props.closeModal(modals.testModalThree);

    return (
        <Modal open size="sm" onClose={onCloseModal}>
            <ModalHeader>Modal 3</ModalHeader>
            <ModalBody>Modal 3</ModalBody>
            <ModalActions />
        </Modal>
    );
};
