import React, { createContext, useState, useContext, useEffect, PropsWithChildren } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";

import { useSessionStorage, useEffectOnce } from "Hooks";
import { getShopsActiveDay, isShopOpen } from "Utils";
import { ActiveHour, EatingPreference, Table, TypeOfServiceDisruption, FoodOptions, BrowseOptions } from "Types";
import { MIDNIGHT_HOURS, SessionConstants, ThirdPartyDeliveryType } from "Constants";
import { convertMomentDayNoToSwe } from "TempUtils";
import { useOnlineStore, useQoplaStore, defaultFoodOptions, useInitializeOnlineStore } from "Stores";
import { useServiceDisruptions } from "./utils";
import { ClientInformationType, ServiceDisruptions, customerTypeToSwedish, OnlinePickupOptions } from "OnlineTypes";

/** @deprecated - Re-export to support importing these types from "OnlineTypes" until completely migrated*/
export type { ClientInformationType, ServiceDisruptions, OnlinePickupOptions };
/** @deprecated - Re-export to support importing these types from "OnlineTypes" until completely migrated*/
export { customerTypeToSwedish };

interface IOnlineContext {
    foodOptions: FoodOptions;
    handleSetFoodOptions: (foodOptions: FoodOptions, shouldUpdateSessionStorage?: boolean) => void;
    browseMode: Record<BrowseOptions, boolean>;
    toggleBrowseModeKey: (key: BrowseOptions, value: boolean) => void;
    resetFoodOptions: () => void;
    isOnlineExpress: boolean;
    onlineActiveHours: ActiveHour[] | [];
    handleSetOnlineActiveHours: (onlineActiveHours: ActiveHour[] | []) => void;
    backendDiff: number;
    setBackendDiff: any;
    setSessionBackendDiff: any;
    setQr: any;
    qr: any;
    clientInfo: ClientInformationType;
    setClientInfo: (clientInformation: ClientInformationType) => void;
    removeClientInfo: () => void;
    getTodaysActiveHour: (activeHours?: ActiveHour[]) => ActiveHour | undefined;
    handleSetTable: (table: Table | null) => void;
    serviceDisruptions: ServiceDisruptions;
}

const OnlineContext = createContext<IOnlineContext | null>(null);

const initialClientInfo = {
    frontendVersion: "",
    startTime: 0,
    firstAddedOrder: 0,
    checkoutTime: 0,
    purchaseTime: 0
};

const OnlineProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [, , removeSessionFoodOptions] = useSessionStorage(SessionConstants.FOOD_OPTIONS);
    const [, setSessionBackendDiff] = useSessionStorage(SessionConstants.BACKEND_DIFF);
    const [, setSessionBrowseMode] = useSessionStorage(SessionConstants.BROWSE_MODE);
    const [sessionQr, setSessionQr] = useSessionStorage(SessionConstants.QR);
    const [sessionClientInfo, setSessionClientInfo, removeClientInfo] = useSessionStorage(
        SessionConstants.CLIENT_INFO,
        initialClientInfo
    );

    const { selectedShop: shop } = useQoplaStore();
    const {
        handleSetTable,
        handleSetOnlineActiveHours,
        onlineActiveHours,
        foodOptions,
        handleSetFoodOptions,
        resetFoodOptionsFromStore,
        browseMode,
        setBrowseMode,
        backendDiff,
        setBackendDiff
    } = useOnlineStore();
    const { isCardServiceDisrupted, isSwishServiceDisrupted, refetchServiceDisruption } = useServiceDisruptions([
        TypeOfServiceDisruption.CARD,
        TypeOfServiceDisruption.SWISH
    ]);

    const location = useLocation();

    const [isOnlineExpress] = useState(() => {
        const onlineExpressRegex = new RegExp("/express/restaurant/");
        return onlineExpressRegex.test(location.pathname);
    });

    useEffectOnce(() => {
        useInitializeOnlineStore();
    });

    useEffect(() => {
        if (!!shop && !!shop.id) {
            const {
                settings: { onlineSettings },
                activeHours,
                publicId
            } = shop;

            const isSameShop = location.pathname.includes(publicId);
            !isSameShop && handleSetFoodOptions(defaultFoodOptions, false);

            const hasCatering =
                onlineSettings.eatingOptions && onlineSettings.eatingOptions.includes(EatingPreference.CATERING);

            handleSetOnlineActiveHours(activeHours);

            const currBrowseMode = {
                ...browseMode,
                [BrowseOptions.IS_TEMPORARILY_OFFLINE]: onlineSettings.restaurantOffline,
                [BrowseOptions.IS_CLOSED]: !hasCatering && !isShopOpen(onlineActiveHours, backendDiff),
                [BrowseOptions.CLOSED_ONLINE_ORDERS]: onlineSettings.closeOnlineOrders,
                [BrowseOptions.IS_PAUSED]: onlineSettings.pauseOnlineOrders,
                [BrowseOptions.RUSH_HOUR]: onlineSettings.rushHour
            };
            setBrowseMode(currBrowseMode);
        }
    }, [shop, onlineActiveHours]);

    const toggleBrowseModeKey = (key: BrowseOptions, value: boolean) => {
        const updatedBrowseMode = {
            ...browseMode,
            [key]: value
        };
        setSessionBrowseMode(updatedBrowseMode);
        setBrowseMode(updatedBrowseMode);
    };

    const resetFoodOptions = () => {
        resetFoodOptionsFromStore();
        removeSessionFoodOptions();
    };

    const getTodaysActiveHour = (activeHours?: ActiveHour[]): ActiveHour | undefined => {
        const today = moment().add(backendDiff, "ms");
        let todayDayNo = today.day();

        if (!browseMode.isClosed && today.hours() < MIDNIGHT_HOURS) {
            todayDayNo -= 1;
        }

        const _activeHours = activeHours ? activeHours : onlineActiveHours;

        const dayOfWeek = convertMomentDayNoToSwe(todayDayNo);
        return getShopsActiveDay(_activeHours, dayOfWeek);
    };

    const value = {
        foodOptions,
        handleSetFoodOptions,
        browseMode,
        toggleBrowseModeKey,
        resetFoodOptions,
        isOnlineExpress,
        handleSetTable,
        onlineActiveHours,
        handleSetOnlineActiveHours,
        backendDiff,
        setBackendDiff,
        qr: parseInt(sessionQr),
        setQr: setSessionQr,
        setSessionBackendDiff,
        clientInfo: sessionClientInfo,
        setClientInfo: setSessionClientInfo,
        removeClientInfo: removeClientInfo,
        getTodaysActiveHour,
        serviceDisruptions: { isCardServiceDisrupted, isSwishServiceDisrupted, refetchServiceDisruption }
    };

    return <OnlineContext.Provider value={value}>{children}</OnlineContext.Provider>;
};

const useOnline = () => {
    const ctx = useContext(OnlineContext);
    if (!ctx) {
        throw new Error("useOnline must be within a OnlineProvider");
    }
    return ctx;
};

export { OnlineContext, OnlineProvider, useOnline, BrowseOptions, EatingPreference };
